exports.components = {
  "component---src-dynamic-pages-news-index-tsx": () => import("./../../../src/dynamic-pages/news/index.tsx" /* webpackChunkName: "component---src-dynamic-pages-news-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-course-high-tsx": () => import("./../../../src/pages/course/high.tsx" /* webpackChunkName: "component---src-pages-course-high-tsx" */),
  "component---src-pages-course-junior-tsx": () => import("./../../../src/pages/course/junior.tsx" /* webpackChunkName: "component---src-pages-course-junior-tsx" */),
  "component---src-pages-course-kids-tsx": () => import("./../../../src/pages/course/kids.tsx" /* webpackChunkName: "component---src-pages-course-kids-tsx" */),
  "component---src-pages-course-primary-tsx": () => import("./../../../src/pages/course/primary.tsx" /* webpackChunkName: "component---src-pages-course-primary-tsx" */),
  "component---src-pages-fee-tsx": () => import("./../../../src/pages/fee.tsx" /* webpackChunkName: "component---src-pages-fee-tsx" */),
  "component---src-pages-form-contact-1-tsx": () => import("./../../../src/pages/form/contact1.tsx" /* webpackChunkName: "component---src-pages-form-contact-1-tsx" */),
  "component---src-pages-form-experience-1-tsx": () => import("./../../../src/pages/form/experience1.tsx" /* webpackChunkName: "component---src-pages-form-experience-1-tsx" */),
  "component---src-pages-form-friends-1-tsx": () => import("./../../../src/pages/form/friends1.tsx" /* webpackChunkName: "component---src-pages-form-friends-1-tsx" */),
  "component---src-pages-form-pamphlet-1-tsx": () => import("./../../../src/pages/form/pamphlet1.tsx" /* webpackChunkName: "component---src-pages-form-pamphlet-1-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-preview-tsx": () => import("./../../../src/pages/news/preview.tsx" /* webpackChunkName: "component---src-pages-news-preview-tsx" */),
  "component---src-pages-news-wp-post-slug-tsx": () => import("./../../../src/pages/news/{wpPost.slug}.tsx" /* webpackChunkName: "component---src-pages-news-wp-post-slug-tsx" */),
  "component---src-pages-pamphlet-tsx": () => import("./../../../src/pages/pamphlet.tsx" /* webpackChunkName: "component---src-pages-pamphlet-tsx" */),
  "component---src-pages-portal-guide-tsx": () => import("./../../../src/pages/portal_guide.tsx" /* webpackChunkName: "component---src-pages-portal-guide-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-renewal-tsx": () => import("./../../../src/pages/renewal.tsx" /* webpackChunkName: "component---src-pages-renewal-tsx" */),
  "component---src-pages-spring-tsx": () => import("./../../../src/pages/spring.tsx" /* webpackChunkName: "component---src-pages-spring-tsx" */),
  "component---src-pages-summer-tsx": () => import("./../../../src/pages/summer.tsx" /* webpackChunkName: "component---src-pages-summer-tsx" */),
  "component---src-pages-trial-tsx": () => import("./../../../src/pages/trial.tsx" /* webpackChunkName: "component---src-pages-trial-tsx" */),
  "component---src-pages-winter-tsx": () => import("./../../../src/pages/winter.tsx" /* webpackChunkName: "component---src-pages-winter-tsx" */)
}

