/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// TODO: 320pxのデバイスサイズで崩れる
import './src/assets/css/global.css'
import './src/assets/css/portal_guide.css'

// require('./src/assets/css/global.css')

// exports.onRouteUpdate = ({}) => {
//   const bodyElement = document.querySelector('body')
//   bodyElement.classList.add('is-loaded')
// }

export const onRouteUpdate = ({ location, prevLocation }) => {
  const targets = document.querySelectorAll('.lazy-css')
  targets.forEach(target => {
    target.setAttribute('rel', 'stylesheet')
  })
}
